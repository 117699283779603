import { getSiteUser } from "@app/core/inspections/[id]/api";
import { Officers } from "@app/core/inspections/[id]/model";
import { optionOfficer } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/details-section/config";
import {
  PermitType,
  PermitTypeUiControl,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { isSuccessResponse } from "@common/apis/util";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import Loading from "@components/loading/Loading";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDetailsSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<PermitType>();

export const DetailsSection = observer(
  ({ formRenderProps }: IDetailsSectionProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { isDeleted } = dataForms?.GeneralUIControl as PermitTypeUiControl;

    const [isLoading, setIsLoading] = useState(false);
    const { valueGetter, onChange } = formRenderProps;
    const getValue = (nameOfField: keyof PermitType) =>
      valueGetter(nameOf(nameOfField));
    const onChangeField = (nameOfField: string, value: any) => {
      onChange(nameOf(nameOfField as keyof PermitType), { value: value });
    };

    const { settings } = useCommonCoreStore();
    const isShowUsePermitEventName = getBoolValueSetting(
      settings[ECorporateSettingsField.LLP_UsePermitEventName]
    );
    const isShowPermitDetailsTab = valueGetter(nameOf("Flag_PermitDetails"));

    const responsibleOfficerChangeHandler = async (officer: Officers) => {
      if (officer) {
        setIsLoading(true);
        const response = await getSiteUser(officer?.ID);
        setIsLoading(false);
        if (isSuccessResponse(response) && response?.data) {
          onChangeField("ResponsibleOfficer", response.data);
          onChangeField("ResponsibleOfficer_ID", response.data.Contact_ID);
        } else {
          appNotificationStore.pushNotification({
            title: "Responsible officer is not found",
            type: "error",
            autoClose: false,
          });
        }
      } else {
        onChangeField("ResponsibleOfficer", undefined);
        onChangeField("ResponsibleOfficer_ID", undefined);
      }
    };

    const tabFields = ["Tab1", "Tab2", "Tab3", "Tab4", "Tab5"];
    const contactFields = [
      nameOf("Contact1Label"),
      nameOf("Contact2Label"),
      nameOf("Contact3Label"),
      nameOf("Contact4Label"),
    ];
    const dimensionFields = [
      nameOf("DimensionLabel1"),
      nameOf("DimensionLabel2"),
      nameOf("DimensionLabel3"),
      nameOf("DimensionLabel4"),
      nameOf("DimensionLabel5"),
      nameOf("DimensionLabel6"),
    ];

    if (isLoading) {
      return <Loading isFullScreen isLoading={isLoading} />;
    }

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Placement?" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_PlacementOptions")}
              name={nameOf("Flag_PlacementOptions")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Purpose of?" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_PurposeOf")}
              name={nameOf("Flag_PurposeOf")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Insurance?" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_InsuranceDetails")}
              name={nameOf("Flag_InsuranceDetails")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Renewable?" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_Renewable")}
              name={nameOf("Flag_Renewable")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Default period" />
            <Field
              disabled={isDeleted}
              component={CCNumericTextBox}
              name={nameOf("DefaultPeriodMonths")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Type of.." />
            <Field
              disabled={isDeleted}
              component={CCInput}
              name={nameOf("TypeOfName")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          {dimensionFields.map(
            (dimensionFieldName: keyof PermitType, index: number) => (
              <div className="cc-field" key={index}>
                <CCLabel title={`Dimension ${index + 1}`} />
                <Field
                  disabled={isDeleted}
                  component={CCInput}
                  name={dimensionFieldName}
                />
              </div>
            )
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Prefix numbering" />
            <Field
              disabled={isDeleted}
              component={CCInput}
              name={nameOf("Numbering_Prefix")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Time" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_Time")}
              name={nameOf("Flag_Time")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Registration number" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_RegistrationNumber")}
              name={nameOf("Flag_RegistrationNumber")}
            />
          </div>
          {isShowUsePermitEventName && (
            <div className="cc-field">
              <CCLabel title="Use event name" />
              <Field
                disabled={isDeleted}
                component={CCSwitch}
                checked={getValue("Flag_UsePermitEventName")}
                name={nameOf("Flag_UsePermitEventName")}
              />
            </div>
          )}
          <div className="cc-field">
            <CCLabel title="Make" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_Make")}
              name={nameOf("Flag_Make")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Model" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_Model")}
              name={nameOf("Flag_Model")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Enable supervisor" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_IsSupervisorEnabled")}
              name={nameOf("Flag_IsSupervisorEnabled")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Enable preferred contact" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_IsPreferredContactEnabled")}
              name={nameOf("Flag_IsPreferredContactEnabled")}
            />
          </div>
        </div>
        <div className="cc-form-cols-3">
          {contactFields.map(
            (contactFieldName: keyof PermitType, index: number) => (
              <div className="cc-field" key={index}>
                <CCLabel title={`Contact ${index + 1}`} />
                <Field
                  disabled={isDeleted}
                  component={CCInput}
                  name={contactFieldName}
                />
              </div>
            )
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Responsible officer" />
            <Field
              disabled={isDeleted}
              component={InputPickerSearch}
              name={nameOf("ResponsibleOfficer")}
              nameDisplay="DisplayName"
              value={getValue("ResponsibleOfficer")}
              options={optionOfficer}
              onChange={responsibleOfficerChangeHandler}
            />
          </div>
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel
              title="On issue email"
              informationTooltip={`Help Hint [60001] - If the system capacity does not exceed 10 EP.`}
            />
            <Field
              disabled={isDeleted}
              name={nameOf("OnIssueEmail")}
              component={CCTextArea}
              rows={3}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Eligibility statement" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_EligibilityStatement")}
              name={nameOf("Flag_EligibilityStatement")}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Eligibility details" />
            <Field
              disabled={isDeleted}
              component={CCTextArea}
              name={nameOf("EligibilityDetails")}
              rows={3}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Permit details" />
            <Field
              disabled={isDeleted}
              component={CCSwitch}
              checked={getValue("Flag_PermitDetails")}
              name={nameOf("Flag_PermitDetails")}
            />
          </div>
        </div>
        {isShowPermitDetailsTab && (
          <div className="cc-form-cols-3">
            {tabFields.map((tabFieldName: string, index: number) => (
              <div className="cc-field" key={index}>
                <CCLabel title={`Tab ${index + 1}`} />
                <Field
                  disabled={isDeleted}
                  component={CCInput}
                  name={tabFieldName}
                />
              </div>
            ))}
          </div>
        )}
      </section>
    );
  }
);
