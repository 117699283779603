import { Invoice } from "@app/core/invoice/[id]/model";
import { useInvoiceStore } from "@app/core/invoice/[id]/store";
import {
  generateInvoiceItemTitle,
  productTypeNumberLogic,
} from "@app/core/invoice/[id]/util";
import InvoiceItems from "@app/core/invoice/invoice-item-accordion/_index";
import { InvoiceItemDialog } from "@app/core/invoice/invoice-item-accordion/components/dialog/invoice-item-dialog/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { InvoiceParentSection } from "./components/invoice-parent-section/_index";

export interface IInvoiceFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<Invoice>();

export const InvoiceFormElement = observer(
  ({ formRenderProps }: IInvoiceFormElementProps) => {
    const isNew = useIsNew();
    const { valueGetter, onChange } = formRenderProps;
    const { id } = useParams<{ id: string }>();

    const { settings } = useCommonCoreStore();

    const {
      invoice,
      setIsShowInvoiceItemDialog,
      isShowInvoiceItemDialog,
      parent,
      isExpandedInvoiceItems,
      forceModified,
    } = useInvoiceStore();

    useEffect(() => {
      if (forceModified) {
        // onChange to force modified
        onChange("isUpdateInvoiceItems", {
          value: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceModified]);

    const isFinanceAllocateInvoiceNumber = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateInvoiceNumber
      ]
    );
    const isEnableMultiLineInvoice = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    );

    const isReadOnlyInvoiceNumber = useMemo(
      () =>
        isFinanceAllocateInvoiceNumber ||
        isEnableMultiLineInvoice ||
        (invoice?.Invoice_ID && invoice?.InvoiceNumber),
      [
        isEnableMultiLineInvoice,
        isFinanceAllocateInvoiceNumber,
        invoice?.Invoice_ID,
        invoice?.InvoiceNumber,
      ]
    );

    const productType: PRODUCT_TYPE_NUMBER = productTypeNumberLogic(
      parent?.recordType
    );

    const listPanelBar: ITabProps[] = [
      {
        isExpanded: isExpandedInvoiceItems,
        title: generateInvoiceItemTitle(settings, RECORDTYPE.CORE_Invoice),
        tabType: TabTableType.Fees,
        component: (
          <InvoiceItems
            id={isNew ? undefined : parseInt(id)}
            recordType={RECORDTYPE.CORE_Invoice}
          />
        ),
      },
    ];

    const invoiceFormObj = valueGetter("");

    return (
      <>
        {isShowInvoiceItemDialog && (
          <InvoiceItemDialog
            onClose={() => {
              setIsShowInvoiceItemDialog(false);
            }}
            productType={productType}
            invoice={isNew ? invoiceFormObj : invoice}
            isNew={isNew}
          />
        )}
        <FormElement>
          <InvoiceParentSection recordType={parent?.recordType} />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Invoice number" />
                <Field
                  name={nameOf("InvoiceNumber")}
                  component={CCInput}
                  readOnly={isReadOnlyInvoiceNumber}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Invoice amount"
                  name={nameOf("InvoiceAmount")}
                  value={valueGetter(nameOf("InvoiceAmount"))}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Invoice balance"
                  name={nameOf("InvoiceBalance")}
                  value={valueGetter(nameOf("InvoiceBalance"))}
                  format={CURRENCY_FORMAT.CURRENCY1}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Invoice date" />
                <Field
                  name={nameOf("InvoiceDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Active date" />
                <Field
                  name={nameOf("InvoiceActiveDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Debtor number"
                  name={nameOf("DebtorNumber")}
                  value={valueGetter(nameOf("DebtorNumber"))}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Due date" />
                <Field
                  name={nameOf("InvoiceDueDate")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Posting date"
                  name={nameOf("InvoicePostingDate")}
                  value={valueGetter(nameOf("InvoicePostingDate"))}
                  format={DATE_FORMAT.DATE}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Export to finance" />
                <Field
                  name={nameOf("ExportedToFinance")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("ExportedToFinance"))}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Description" />
                <Field
                  name={nameOf("Description")}
                  component={CCTextArea}
                  rows={4}
                />
              </div>
            </div>
          </div>
          <hr className="cc-divider" />
          <div className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCValueField
                  label="BPay reference"
                  name={nameOf("BPayReference")}
                  value={valueGetter(nameOf("BPayReference"))}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="BillPay reference"
                  name={nameOf("BillPayReference")}
                  value={valueGetter(nameOf("BillPayReference"))}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Bpay iCRN"
                  name={nameOf("BPAY_iCRN")}
                  value={valueGetter(nameOf("BPAY_iCRN"))}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Keyline reference"
                  name={nameOf("KeyLineReference")}
                  value={valueGetter(nameOf("KeyLineReference"))}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="OCR reference"
                  name={nameOf("OCRReference")}
                  value={valueGetter(nameOf("OCRReference"))}
                />
              </div>
            </div>
          </div>
          {/* Invoice Items Accordion */}
          <br />
          <div className="cc-custom-sub-panel-bar">
            <TabTable
              id={isNew ? undefined : parseInt(id)}
              recordType={RECORDTYPE.CORE_Invoice}
              initialPanels={listPanelBar}
            />
          </div>
        </FormElement>
      </>
    );
  }
);
