import { setupPermitPickSiteAddress } from "@app/products/local-laws/[id]/api";
import {
  IHandlerEventInitialData,
  Permit,
  PermitRegisterHandlerRequest,
  PermitRegisterUIControl,
  PermitUpdateTriggers,
  Svc_FormAction_PermitRegister,
  Svc_Permit,
} from "@app/products/local-laws/[id]/model";
import { renderHTML } from "@app/products/local-laws/[id]/util";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { isHTML } from "@components/cc-input-picker/util";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCValueField } from "@components/cc-value-field/_index";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, trim } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

interface IAddressSectionProps {
  formRenderProps: FormRenderProps;
}

const nameOfSvcPermit = nameOfFactory<Svc_Permit>();
const nameOfPermit = nameOfFactory<Permit>();
const getNameOf = (permitPropsName: keyof Permit) => {
  return `${nameOfSvcPermit("Permit")}.${nameOfPermit(permitPropsName)}`;
};

export const PermitAddressSection = observer(
  ({ formRenderProps }: IAddressSectionProps) => {
    //#region STORE ========/
    const { setInitialDataForms, dataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const uiControlStore =
      dataForms?.GeneralUIControl as PermitRegisterUIControl;
    //#endregion STORE =====/

    //#region STATE ========/
    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingDialog, setIsLoadingDialog] = useState(false);
    //#endregion STATE =====/

    //#region REF ========/
    const prevSiteAddressDisplayName = useRef<string>("");
    //#endregion REF =====/

    const { valueGetter, onChange } = formRenderProps;
    const getValue = (name: keyof Permit) => valueGetter(getNameOf(name));
    const permitObj = valueGetter(nameOfSvcPermit("Permit"));

    //#region HANDLER ========/
    const pickSiteAddressHandler = (
      buildAddress: Address_BuildAddress | null
    ) => {
      if (buildAddress) {
        if (
          isEmpty(trim(buildAddress?.Address?.Formatted_AddressLine1)) &&
          isEmpty(buildAddress?.Address?.Location_Description) &&
          isEmpty(buildAddress?.Address?.Postcode) &&
          isEmpty(buildAddress?.Address?.State)
        ) {
          onChange(getNameOf("Address"), { value: undefined });
          return;
        }

        const permitRegisterHandlerRequest: PermitRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PermitRegister.Form_PickSiteAddress,
          Permit: permitObj,
          PermitRegisterArgs: buildAddress,
          IsFirstTimeLoad: false,
        };

        const handlerInitialData: IHandlerEventInitialData = {
          permitRegisterHandlerRequest,
          errorMsg: "Select address failed.",
        };

        fetchApiByAlias("permitHandlerSlider", {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOf("Address"), { value: null });
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore.LitAddress,
              Value: "",
            },
          },
        });
      }
    };
    //#endregion HANDLER =====/

    //#region UI ========/
    const handleOpenDialog = async () => {
      if (!uiControlStore) return;
      setIsLoadingDialog(true);
      const response = await setupPermitPickSiteAddress(
        valueGetter(getNameOf("Address"))
      );
      setIsLoadingDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const updateSaveTriggers = (triggers: PermitUpdateTriggers) => {
      let saveTriggers: PermitUpdateTriggers[] =
        getValue("_SaveTriggers") ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOf("_SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const removeDisplayValueSiteAddress = () => {
      if (uiControlStore) {
        prevSiteAddressDisplayName.current = uiControlStore?.LitAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore.LitAddress,
              Value: permitObj?.Address?.Formatted_SingleLine ?? "",
            },
          },
        });
      }
    };

    const restoreDisplayValueSiteAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevSiteAddressDisplayName.current) &&
        valueGetter(`${getNameOf("Address")}.Formatted_SingleLine`)
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore.LitAddress,
              Value: prevSiteAddressDisplayName.current ?? "",
            },
          },
        });
      }
    };
    //#endregion UI =====/

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Site address" isMandatory />
            <Field
              uniqueKey="PermitSiteAddress"
              name={getNameOf("Address")}
              component={PropertyDetail}
              initialData={initialBuildAddress}
              placeholder={"Select site address"}
              formRenderProps={formRenderProps}
              validator={requiredValidator}
              isSearchPropertyAddresses={true}
              onError={(error: any) => {
                pushNotificationPortal({
                  type: "error",
                  title: "Select site address failed.",
                  description: error,
                  autoClose: false,
                });
              }}
              value={uiControlStore?.LitAddress?.Value ?? ""}
              onChangeEventHandler={pickSiteAddressHandler}
              updateSaveTriggers={() => {
                updateSaveTriggers(PermitUpdateTriggers.UpdateAddress);
              }}
              onSubmit={pickSiteAddressHandler}
              onButtonClick={handleOpenDialog}
              isLoadingDialog={isLoadingDialog}
              removeDisplayValue={removeDisplayValueSiteAddress}
              restoreDisplayValue={restoreDisplayValueSiteAddress}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Owner"
              value={renderHTML(uiControlStore?.LitOwners?.Value)}
            />
          </div>
          <div className="cc-field">
            <CCValueField
              label="Details"
              value={renderHTML(uiControlStore?.LitDetails?.Value)}
            />
          </div>
        </div>
      </section>
    );
  }
);
